<template>
    <div class="mobile-header">
        <a href="/">
            <img class="logo" :src="logoSrc" alt="logo pos" id="logo">
        </a>
        <div class="d-flex align-items-center gap-4">
            <div class="toggle-btn" @click="$emit('toggle-theme')">
                <i class="bx" :class="isDarkTheme ? 'bxs-sun' : 'bxs-moon'"></i>
            </div>
            <div class="menu-btn fa-solid fa-bars" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"></div>    
        </div>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header d-flex justify-content-between">
                <h5 id="offcanvasRightLabel">Power Of Sharing</h5>
                <button type="button" class="close-btn" data-bs-dismiss="offcanvas" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div class="offcanvas-body">
                <li><router-link class="link" to="/#accueil">Accueil</router-link></li>
                <li><router-link class="link" to="/#apropos">A propos</router-link></li>
                <li><router-link class="link" to="/#formations">Formations</router-link></li>
                <li><router-link class="link" to="/developpement-digital">Developpement digital</router-link></li>
                <li><router-link class="link" to="#contact">Contact</router-link></li>
            </div>
        </div>
    </div> 
</template>

<script>
export default {
    props: {
        isDarkTheme : Boolean
    },
    computed: {
        logoSrc() {
            return this.isDarkTheme ? '/images/logo-white.svg' : '/images/logo.webp';
        }
    },
    mounted () {
        const header = document.querySelector(".mobile-header");
        window.addEventListener("scroll", function() {
            header.classList.toggle ("sticky",window.scrollY > 130);
        });
    }
}
</script>

<style>

</style>