<template>
  <div id="app">
    <Preloader :isLoading="isLoading"/>
    <div v-if="!isLoading">
      <DefaultHeader :isDarkTheme="isDarkTheme" @toggle-theme="toggleTheme"/>
      <MobileHeader :isDarkTheme="isDarkTheme" @toggle-theme="toggleTheme"/>
      <BtnScroll/>
      <!-- home section start -->
      <div class="home" id="accueil">
          <div class="background-shapes position">
              <div class="shape-group">
                  <div class="shape bg-shape1 bg-teal"></div>
                  <div class="shape bg-shape2 bg-primary"></div>
                  <div class="shape bg-shape1 bg-purple"></div>
              </div>
          </div>
          <div class="home-content" data-aos="fade-down">
              <div class="text">
                  <h1 class="h1">Découvrez notre large gamme de formations professionnelles et nos services de développement digital.</h1>
                  <div class="group-button">
                      <router-link class="btn" to="/#formations">Formations</router-link>
                      <router-link to="/developpement-digital/#nos-produits" class="btn btn-2">Nos produits</router-link>
                  </div>
              </div>
          </div>
      </div>
      <!-- home section end -->
      <router-view/>
      <!-- contact section start -->
      <div class="contact" id="contact"
        v-for="contact in contacts"
        :key="contact.id">
          <div class="background-shapes position">
            <div class="shape-group">
              <div class="shape bg-shape1 bg-teal"></div>
              <div class="shape bg-shape2 bg-primary"></div>
              <div class="shape bg-shape1 bg-purple"></div>
            </div>
          </div>
          <div class="section">
              <div class="row gx-4 gy-4">
                  <div class="col-lg-7">
                      <div class="info-contact" data-aos="fade-left">
                          <div class="title">
                              <h6 class="h6">Get in Touch</h6>
                              <h1>Prêt à Démarrer Votre Projet ?</h1>
                              <p>Contactez-nous dès aujourd'hui pour discuter de vos idées et découvrir comment nous pouvons vous aider à les concrétiser. Notre équipe est prête à relever tous les défis pour vous fournir des solutions digitales innovantes.</p>
                              <hr>
                          </div>
                          <div class="info d-flex flex-column gap-4">
                              <div class="col mt-2 content-info">
                                  <div class="icon">
                                      <i class="fa-solid fa-location-dot"></i>
                                  </div>
                                  <div class="detail">
                                      <h6 class="h6">Adresse</h6>
                                      <a>{{ contact.adress }}</a>
                                  </div>
                              </div>
                              <div class="col content-info">
                                  <div class="icon">
                                      <i class="fa-solid fa-envelope"></i>
                                  </div>
                                  <div class="detail">
                                      <h6>Adresse mail</h6>
                                      <a :href="'mailto:' + contact.mail">{{ contact.mail }}</a>
                                  </div>
                              </div>
                              <div class="col content-info"
                              v-if="contact.phone">
                                  <div class="icon">
                                      <i class="fa-solid fa-phone"></i>
                                  </div>
                                  <div class="detail">
                                      <h6>Numéro téléphone</h6>
                                      <a :href="'tel:'+ contact.phone">{{ contact.phone }}</a>
                                  </div>
                              </div>
                          </div>
                          <div class="social-media">
                              <a :href="contact.facebook" target="_blank"> <i class="fa-brands fa-facebook-f"></i></a>
                              <a :href="contact.instagram" target="_blank"> <i class="fa-brands fa-instagram"></i></a>
                              <a :href="contact.tiktok" target="_blank"> <i class="fa-brands fa-tiktok"></i></a>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-5 form-contact text-center" data-aos="fade-right">
                      <div class="title">
                          <h4>Contactez-nous</h4>
                          <p>Nous serions ravis de recevoir vos questions, commentaires ou suggestions.</p> 
                      </div>
                      <Form/>
                  </div>
              </div>
          </div>
          <div class="map">
            <iframe :src="contact.map" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
      </div>
      <!-- contact section end -->
      <DefaultFooter :isDarkTheme="isDarkTheme" />
    </div>
  </div>
</template>

<script>
import BtnScroll from './components/elements/BtnScroll.vue';
import DefaultFooter from './components/partial/footer/DefaultFooter.vue';
import DefaultHeader from './components/partial/headers/DefaultHeader.vue';
import MobileHeader from './components/partial/headers/MobileHeader.vue';
import axios from 'axios';
import { BASE_API_URL } from '@/config/apiConfig';
import Form from './components/partial/Form.vue';
import Preloader from './components/elements/Preloader.vue';
export default {
  components: {
    DefaultHeader, MobileHeader, BtnScroll, DefaultFooter, Form, Preloader
  },
  data() {
    return {
      contacts: {},
      isLoading: true,
      isDarkTheme: false
    }
  },
  created () {
    this.fetchContact();
  },
  mounted() {
    //preloader
    setTimeout(() => {
      this.isLoading = false;
    }, 3000),
    //dark mode
    this.checkTheme();
  },
  methods: {
    //////axios fetch contact
    async fetchContact(){
      try{
        const resp = await axios.get(`${BASE_API_URL}/contact.php`);
        this.contacts = resp.data ;
      }catch (error) {
          console.error('Erreur lors de la récupération des données:', error);
      } 
    },
    ////////////darkmode
    toggleTheme() {
      this.isDarkTheme = !this.isDarkTheme;
      document.body.classList.toggle('dark-theme', this.isDarkTheme);
      localStorage.setItem('theme', this.isDarkTheme ? 'dark-theme' : '');
    },
    checkTheme() {
      const currentTheme = localStorage.getItem('theme');
      if (currentTheme === 'dark-theme') {
        this.isDarkTheme = true;
        document.body.classList.add('dark-theme');
      } else {
        this.isDarkTheme = false;
        document.body.classList.remove('dark-theme');
      }
    }
  },
}
</script>

