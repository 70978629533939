<template>
  <div class="cards">
        <input type="checkbox" class="btnBox" id="btnBox">
        <div class="row g-3">  
            <div class="card-content col-xl-3 col-lg-4 col-md-6 col-sm-6"
                v-for="formation in formations"
                :key="formation.id">
                <div class="card">
                <div class="card-img skelton">
                    <img :src="formation.image" :alt="formation.title" class="card-img-top">                   
                </div>
                <div class="card-body">
                    <h6 class="card-title skelton">{{ formation.title }}</h6>
                    <p class="card-text skelton">{{ formation.description }}</p>
                </div>
                </div>
            </div>
        </div>
        <label for="btnBox"
            class="btn-area mt-4 d-flex justify-content-center"
            v-if="formations.length > 4">
            <span class="load more">Voir plus</span>
            <span class="load less">Voir moins</span>
        </label>
    </div>
</template>

<script>
import axios from 'axios';
import { BASE_API_URL } from '@/config/apiConfig';

export default {
    name: "FormationsCards",
    data() {
      return {
        formations: [],
      };
    },
    created() {
        this.fetchFormations();
    },
    methods: {
      removeSkeleton() {
        const allSkeleton = document.querySelectorAll('.skelton');
        allSkeleton.forEach(item => {
          item.classList.remove('skelton');
        });
      },
      async fetchFormations() {
        try{
          const resp = await axios.get(`${BASE_API_URL}/formations.php`);
          this.formations = resp.data;
          setTimeout(() => {
            this.removeSkeleton();
          },2000);
        }catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
        } 
      },
    }
}
</script>

<style>

</style>