<template>
    <div class="form-contact">
        <form @submit.prevent="submitForm">
            <div class="row">
                <div class="mb-3">
                    <input type="text" class="form-control" v-model="name" name="name" placeholder="Nom" required>
                </div>
                <div class="mb-3">
                    <input type="text" class="form-control" v-model="company" name="company" placeholder="Entreprise" required>
                </div>
                <div class="mb-3">
                    <input type="email" class="form-control" v-model="email" name="email" placeholder="Email" required>
                </div>
                <div class="mb-3">
                    <input type="tel" class="form-control" v-model="phone" name="phone" placeholder="Téléphone" required>
                </div>
                <div>
                    <textarea style="height: 130px;" class="form-control" v-model="message" name="message" placeholder="Message ..." required></textarea>
                </div>
                <div class="mt-3">
                    <input class="btn" type="submit" value="Envoyer">
                    <p class="text-start pt-2">{{ result }}</p>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
const WEB3FORMS_ACCESS_KEY = "6d6db64f-095e-4946-a413-1148a567177c";

export default {
    name: "formComp",
    data() {
        return {
            name: "",
            company: "",
            email: "",
            phone: "",
            message: "",
            result: ""  // To store the result message
        };
    },
    methods: {
        async submitForm() {
            this.result = "Please wait...";
            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    access_key: WEB3FORMS_ACCESS_KEY,
                    name: this.name,
                    company: this.company,
                    email: this.email,
                    phone: this.phone,
                    message: this.message,
                    subject: "POWER OF SHARING",  // Sending subject directly in the request
                }),
            });
            const result = await response.json();

            // Update the result message based on the response
            if (result.success) {
                this.result = "Votre message a été envoyé avec succès.";
            } else {
                this.result = "Erreur lors de l'envoi de votre message. Veuillez réessayer.";
            }

            // Optionally clear the form fields after submission
            this.name = "";
            this.company = "";
            this.email = "";
            this.phone = "";
            this.message = "";
        },
    },
};
</script>
