<template>
    <div class="footer pb-2">
      <div class="d-flex justify-content-center gap-3 align-items-center flex-column">
        <!-- Use logoSrc computed property based on isDarkTheme -->
        <img class="logo" data-aos="fade-down" :src="logoSrc" alt="logo pos" id="logo">
        <div class="d-flex gap-4 flex-wrap justify-content-center fw-medium" data-aos="zoom-in">
          <li><router-link class="link" to="/#accueil">Accueil</router-link></li>
          <li><router-link class="link" to="/#apropos">A propos</router-link></li>
          <li><router-link class="link" to="/#formations">Formations</router-link></li>
          <li><router-link class="link" to="/developpement-digital">Developpement digital</router-link></li>
          <li><router-link class="link" to="#contact">Contact</router-link></li>
        </div>
        <hr class="border-1 w-75">
        <h6 class="text-center">&copy;2024 Power of Sharing | Tous les droits réservés.</h6>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isDarkTheme: Boolean // Correctly pass the isDarkTheme prop
    },
    computed: {
      logoSrc() {
        // Return the correct logo based on dark mode
        return this.isDarkTheme ? '/images/logo-white.svg' : '/images/logo.webp';
      }
    }
  }
  </script>
  