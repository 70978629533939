<template>
  <div>
    <!-- about secction start -->
    <div class="about section d-flex flex-column gap-5" id="apropos">
        <div class="circle"></div>
        <div>
          <div class="text">
              <p class="lead" data-aos="fade-down">Chez POWER OF SHARING, nous sommes passionnés par l’apprentissage et l’innovation. Notre mission est de vous fournir des formations de qualité et de vous accompagner dans vos projets digitaux.</p>
          </div>
          <AboutServices/>
        </div> 
    </div>
    <!-- about secction end -->
    <!-- formations section start -->
    <div class="formations" id="formations">
        <div class="circle-1"></div>
        <div 
          class="formation-content section d-flex align-items-center flex-column gap-5">
            <div class="title text-center">
                <h6 class="h6" data-aos="fade-down">bienvenue à POS</h6>
                <h1  data-aos="fade-down">Explorez notre Programme d'Apprentissage</h1>
            </div>
            <Formations/>
        </div>
    </div> 
    <!-- formations section end -->
    <!-- center section start -->
    <div class="center-section section">
        <div class="content">
          <h1 class="h1 fw-bold" data-aos="fade-down">Nos experts en développement digital sont prêts à vous accompagner dans la réalisation de vos projets.</h1>
          <div>
            <router-link to="/developpement-digital" class="btn my-3" data-aos="fade-down">Savoir plus</router-link>
          </div>
        </div>
    </div>
    <!-- center section end -->
  </div>
</template>

<script>


import AboutServices from '@/components/partial/cards/AboutServices.vue';
import Formations from '@/components/partial/cards/Formations.vue';
export default {
    components: {
      AboutServices, Formations
    },
};
</script>