<template>
    <div class="cards">
        <div class="row gx-5">
            <div class="col-md-4 col-sm-6 text-center"
                v-for="service in services" :key="service.id">
                <div class="d-flex align-items-center flex-column gap-2 card-detail">
                    <i :class="service.icon" data-aos="fade-down"></i>
                    <h5 class="h5" data-aos="fade-down">{{ service.title }}</h5>
                    <p  data-aos="fade-down">{{ service.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutServices",
    data() {
        return {
            services: [
                {
                    "icon": "fas fa-handshake",
                    "title": "Engagement",
                    "description": "Nous nous engageons à fournir un service de qualité et à répondre aux besoins de nos clients."
                },
                {
                    "icon": "fas fa-lightbulb",
                    "title": "Innovation",
                    "description": "Nous sommes constamment à la recherche de nouvelles méthodes et technologies pour améliorer nos services."  
                },
                {
                    "icon": "fas fa-smile",
                    "title": "Satisfaction Client",
                    "description": "Notre priorité absolue : dépasser les attentes de nos clients."
                }
            ]
        }
    },
}
</script>

<style>

</style>