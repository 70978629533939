<template>
    <div v-if="isLoading" class="preloader">
      <div class="spinner"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "PreloaderComponent",
    props: {
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style>
  </style>
  