<template>
    <div class="header">
        <a href="/">
            <img class="logo" :src="logoSrc" alt="logo power of sharing" id="logo">
        </a>
        <div class="navbar">
            <li><router-link class="link" to="/#accueil">Accueil</router-link></li>
            <li><router-link class="link" to="/#apropos">A propos</router-link></li>
            <li><router-link class="link" to="/#formations">Formations</router-link></li>
            <li><router-link class="link" to="/developpement-digital">Developpement digital</router-link></li>
        </div>

        <div class="d-flex align-items-center gap-5">
            <div class="toggle-btn" @click="$emit('toggle-theme')">
                <i class="bx" :class="isDarkTheme ? 'bxs-sun' : 'bxs-moon'"></i>
            </div>
            <router-link class="btn" to="#contact">Contact</router-link>
        </div>
    </div> 
</template>

<script>
export default {
    props: {
        isDarkTheme : Boolean
    },
    methods: {
        goToAbout() {
        this.$router.push('/#apropos')
        },
    },
    computed: {
        logoSrc() {
            return this.isDarkTheme ? '/images/logo-white.svg' : '/images/logo.webp';
        }
    },
    mounted () {
        const header = document.querySelector(".header");
        window.addEventListener("scroll", function() {
            header.classList.toggle ("sticky",window.scrollY > 130);
        });
    }
}
</script>

<style>

</style>