<template>
    <!-- <button @click="topFunction()" class="btn-scroll" id="scrollToTopBtn" title="Go to top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </button> -->
    <button
      @click="scrollToTop"
      :class="['btn-scroll', { show: showButton }]"
      id="scrollToTopBtn"
      title="Go to top"
    >
      <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </button>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      this.showButton = window.scrollY > 20;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>


<style>

</style>